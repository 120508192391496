import React from 'react'
import {Link} from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Image from '../components/image'

const NotFoundPage = () => (
  <Layout>
    <SEO title='404: Not found' />
    <section className='p-2'>
      <div className='container mx-auto text-center'>
        <h1 className='md:text-6xl mt-12 font-black text-orange-500 xs:text-4xl sm:text-4xl'>
          404
        </h1>
        <h1 className='md:text-4xl mt-2 xs:text-xl sm:text-xl'>
          Página no encontrada
        </h1>
        <div className='md:w-120 xs:w-96 sm:w-96  is-center'>
          <Image
            name='error'
            alt='404'
            width=''
            height=''
            className='md:h-120 xs:h-96 sm:h-96  w-full object-cover object-center'
          />
        </div>
        <button className='mt-4 mb-6 bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 border border-orange-700 rounded'>
          <Link to='/'>Ir a Inicio</Link>
        </button>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
